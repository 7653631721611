import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  GatsbyImage,
  getImage,
  withArtDirection,
} from "gatsby-plugin-image";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const options = {
  buttons: {
    backgroundColor: "#f58026",
  },
};

const Gallerydetails = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { regex: "/gallery//" } }) {
        edges {
          node {
            name
            relativeDirectory
            relativePath
            publicURL
            base
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [WEBP])
            }
          }
        }
      }
    }
  `);
  // Build our product image database
  const images = [];
  const nodeByName = {};
  const imageNames = [];

  data.allFile.edges.forEach((edge) => {
    const imgName = edge.node.name;
    if (imgName.indexOf("bg_") === 0) {
      imageNames.push(imgName);
    }
    nodeByName[imgName] = edge.node;
  });

  // Loop through found images
  let i = 0;
  imageNames.sort().forEach((n) => {
    const node = nodeByName[n];

    if (node) {
      const bigImage = nodeByName[n.replace("bg_", "b_")];
      // console.log("Checking node: "+n+" found: "+n.replace("b_", "bg_")+" = "+!!bigImage);
      if (bigImage) {
        i++;
        const tag = node.relativeDirectory.split("gallery/")[1];
        let name;

        switch (tag) {
          case "curtain":
            name = "Curtains";
            break;
          case "roller":
            name = "Rollers";
            break;
          case "venetian":
            name = "Venetians";
            break;
          case "shutter":
            name = "Shutters";
            break;
          case "roman":
            name = "Romans";
            break;
          case "honeycomb":
            name = "Honeycomb";
            break;
          case "automation":
            name = "Home Automation";
            break;
          case "external":
            name = "External Blinds";
            break;
          default:
            name = "unknown";
        }

        images.push({
          id: i,
          tag: tag,
          imageSmall: node.childImageSharp,
          imageBig: bigImage.childImageSharp,
          name: name,
          bigName: bigImage.publicURL,
          smallName: node.publicURL,
        });
      }
    }
  });

  const [tag, setTag] = useState("all");
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    tag === "all"
      ? setFilteredImages(images)
      : setFilteredImages(images.filter((image) => image.tag === tag));
  }, [tag]);

  const TagButton = ({ names, realName, handleSetTag, tagActive }) => {
    return (
      <button
        className={`list-btn ${tagActive ? "active" : "null"}`}
        onClick={() => handleSetTag(names)}
      >
        {realName}
      </button>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Gallery - Blinds | Shutters | Curtains | Birk</title>
        <meta
          property="og:title"
          content="Gallery - Blinds | Shutters | Curtains | Birk"
        />
        {/* <meta keywords */}
        <meta
          name="keywords"
          content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
        />
        <meta
          name="description"
          content="Our client portfolio.Please contact for Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, Extertnal Range, Automation, Honecomb Blinds. We are operated in Melbourne and Brisbane"
        />
        <meta
          property="og:description"
          content="Our client portfolio.Please contact for Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, Extertnal Range, Automation, Honecomb Blinds. We are operated in Melbourne and Brisbane"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://birk.itechnotion.com/gallery" />
        <link rel="canonical" href="http://birk.itechnotion.com/gallery" />
      </Helmet>
      <SimpleReactLightbox>
        <Header />
        <section className="product-section">
          <div className="container-fluid all-section">
            <div className="row">
              <div className="heading">
                <h2>Gallery</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <div className="portfolio-menu">
                  <TagButton
                    className="list-btn"
                    names="all"
                    realName="All"
                    handleSetTag={setTag}
                    tagActive={tag === "all" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="roller"
                    realName="Roller Blinds"
                    handleSetTag={setTag}
                    tagActive={tag === "roller" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="roman"
                    realName="Roman Blinds"
                    handleSetTag={setTag}
                    tagActive={tag === "roman" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="curtain"
                    realName="Curtains"
                    handleSetTag={setTag}
                    tagActive={tag === "curtain" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="shutter"
                    realName="Shutters"
                    handleSetTag={setTag}
                    tagActive={tag === "shutter" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="venetian"
                    realName="Venetians"
                    handleSetTag={setTag}
                    tagActive={tag === "venetian" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="external"
                    realName="External Blinds"
                    handleSetTag={setTag}
                    tagActive={tag === "external" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="automation"
                    realName="Home Automation"
                    handleSetTag={setTag}
                    tagActive={tag === "automation" ? true : false}
                  />
                  <TagButton
                    className="list-btn"
                    names="honeycomb"
                    realName="Honeycomb Blinds"
                    handleSetTag={setTag}
                    tagActive={tag === "honeycomb" ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-8 col-lg-9 mt-2 portfolio-item">
                <SRLWrapper options={options}>
                  {filteredImages.map((item) => (
                    <div
                      className="col-12 col-md-6 col-lg-4 item"
                      key={item.id}
                      id={item.tag}
                    >
                      <a href={item.bigName}>
                        <GatsbyImage
                          image={getImage(item.imageSmall)}
                          srl_gallery_image="true"
                        />
                      </a>

                      {/* <a href={item.bigName}>
          <Image  className="galleryLargeImage" 
          src={item.smallName}
          alt="Picture of the author" 
          srl_gallery_image="true"
        />
                      </a> */}

                      <h5>{item.name}</h5>
                    </div>
                  ))}
                </SRLWrapper>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </SimpleReactLightbox>
    </React.Fragment>
  );
};

export default Gallerydetails;
